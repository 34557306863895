import React from 'react';
import cc from 'classcat';

export interface Props {
  children?: React.ReactNode;
  className?: string;
  id: string;
  subTitle?: React.ReactNode;
  title?: React.ReactNode;
  [attr: string]: any;
}

const Section: React.FC<Props> = (props) => {
  const { children, id, subTitle, title, className, ...restProps } = props;

  return (
    <section
      {...restProps}
      id={id}
      className={cc([
        'fc-section',
        'my-8',
        'lg:my-16',
        'mx-auto',
        'max-w-screen-xl',
        'container',
        className,
      ])}
    >
      {title && (
        <h2 className="mb-8 mx-4 text-4xl sm:text-6xl leading-8 sm:leading-10 lg:text-center font-bold font-display">
          {title}
        </h2>
      )}

      {subTitle && (
        <div className="lg:text-center mb-10">
          <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-700 dark:text-gray-500 lg:mx-auto mx-4">
            {subTitle}
          </p>
        </div>
      )}

      {children}
    </section>
  );
};

Section.displayName = 'FcSection';

export default Section;
