import React from 'react';
import cc from 'classcat';

import Button, { Props as ButtonProps } from '../button';
import Icon from '../icon';

export interface Props extends ButtonProps {
  children: React.ReactNode
}

const ArrowButton: React.FC<Props> = (props) => {
  const { children, className, ...restProps } = props;

  return (
    <Button
      className={cc([
        'group',
        className,
      ])}
      {...restProps}
    >
      <div className="inline-flex flex-row items-center">
        {React.Children.only(children)}

        <Icon
          name="ArrowRight"
          className={cc([
            'h-4',
            'w-4',
            'ml-1',
            'transform',
            'group-hover:translate-x-1',
            'motion-safe:transition-transform',
          ])}
        />
      </div>
    </Button>
  );
};

ArrowButton.displayName = 'FcArrowButton';

export default ArrowButton;
