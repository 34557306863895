import React from 'react';
import { PageProps, graphql } from 'gatsby';
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../components/layout';
import SEO from '../components/seo';

import ArrowButton from '../components/arrow-button';
import Breadcrumbs from '../components/breadcrumbs';
import Card from '../components/card';
import Section from '../components/section';

interface Policy {
  id: string;
  policyId: string;
  policyName: string;
  effectiveAsOf: string;
  shortDescription: {
    shortDescription: string;
  };
}

interface DataProps {
  policies: {
    edges: {
      policy: Policy;
    }[];
  };
}

const LegalPage: React.FC<PageProps<DataProps>> = (props) => {
  const {
    data: { policies },
    location,
  } = props;

  const intl = useIntl();

  return (
    <>
      <SEO
        title={intl.formatMessage({
          id: 'Privacy & Terms',
          defaultMessage: 'Privacy & Terms',
        })}
      />

      <Layout
        breadcrumbs={
          <Breadcrumbs
            items={[
              { to: '/', title: intl.formatMessage({ id: 'Start', defaultMessage: 'Start' }) },
              {
                to: '/legal',
                title: intl.formatMessage({
                  id: 'Policies',
                  defaultMessage: 'Policies',
                }),
              },
            ]}
          />
        }
        path={location.pathname}
        title={intl.formatMessage({ id: 'Privacy & Terms', defaultMessage: 'Privacy & Terms' })}
      >
        <Section
          id="policies"
          title={intl.formatMessage({ id: 'Policies', defaultMessage: 'Policies' })}
        >
          <div className="mx-auto mt-4 leading-relaxed px-4 md:px-8 grid gap-8 grid-col-1 md:grid-cols-2 lg:grid-cols-3">
            {policies.edges.map(({ policy }) => (
              <Card
                key={policy.id}
                title={policy.policyName}
                footer={
                  <div className="text-right">
                    <ArrowButton
                      href={`/legal/${policy.policyId}`}
                      small={true}
                      type="internal"
                      variant="tertiary"
                    >
                      <FormattedMessage
                        id="Read our {topic}"
                        defaultMessage="Read our {topic}"
                        values={{
                          topic: policy.policyName,
                        }}
                      />
                    </ArrowButton>
                  </div>
                }
              >
                {policy.shortDescription.shortDescription}
              </Card>
            ))}
          </div>
        </Section>
      </Layout>
    </>
  );
};

export default LegalPage;

export const query = graphql`
  query LegalPage($locale: String) {
    policies: allContentfulPolicy(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: policyName }
    ) {
      edges {
        policy: node {
          id
          policyId
          policyName
          effectiveAsOf
          shortDescription {
            shortDescription
          }
        }
      }
    }
  }
`;
