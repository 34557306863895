import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import { ChevronRightIcon } from '@heroicons/react/solid';

export interface Props {
  items: {
    to: string;
    title: string;
  }[];
}

const Breadcrumbs: React.FC<Props> = (props) => {
  const { items } = props;

  return (
    <div className="fc-breadcrumbs">
      {items.map((item, i, arr) => (
        <span key={item.to} className="fc-breadcrumb inline-block leading-tight align-middle">
          <Link
            to={item.to}
            className="mr-1 p-1 font-bold hover:bg-primary focus-visible:bg-primary hover:bg-opacity-10 focus-visible:bg-opacity-10 focus-visible:ring-2 ring-primary rounded transition-colors duration-150 outline-none"
          >
            {item.title}
          </Link>
          {i < arr.length - 1 && (
            <ChevronRightIcon className="h-5 mr-1 inline-block" />
          )}
        </span>
      ))}
    </div>
  );
};

Breadcrumbs.displayName = 'FcBreadcrumbs';

export default Breadcrumbs;
