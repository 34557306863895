import React from 'react';
import cc from 'classcat';

export interface Props {
  children: React.ReactNode;
  className?: string;
  flat?: boolean;
  footer?: React.ReactNode;
  image?: React.ReactElement;
  title: React.ReactNode;
  [attr: string]: any;
}

const Card: React.FC<Props> = (props) => {
  const {
    children,
    className,
    flat,
    footer,
    image,
    title,
    ...restProps
  } = props;

  return (
    <div
      {...restProps}
      className={cc([
        'fc-card',
        'mb-4',
        'rounded',
        'overflow-hidden',
        'flex',
        'flex-col',
        // 'justify-between',
        'bg-white',
        'dark:bg-gray-800',
        'transition-colors',
        'duration-200',
        {
          'shadow-lg': !flat,
          'focus-visible:shadow-xl': !flat,
          'hover:shadow-xl': !flat,
        },
        className,
      ])}
    >
      {image && React.cloneElement(image, { className: 'h-full' })}
      <div className="h-full px-6 py-4">
        <div className="font-bold text-2xl mb-2 font-display">
          {title}
        </div>
        <div className="prose text-gray-700 dark:text-gray-300 text-lg">
          {children}
        </div>
      </div>
      {
        footer &&
        <div className="px-6 py-4 bg-gray-100 dark:bg-gray-700">
          {footer}
        </div>
      }
    </div>
  );
};

Card.displayName = 'FcCard';

export default Card;


